import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import TripCounter from './components/TripCounter.js';
import TripMap     from './components/TripMap.js';
import TripSlider  from './components/TripSlider.js';

function App() {
  return (
      <>
        <TripCounter />
        <TripMap />
        <TripSlider />
      </>
  );
}

export default App;
