import React from 'react';
import {Marker, InfoWindow} from '@react-google-maps/api';

import InfoWindowContent from './InfoWindowContent';

class Makers extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            selectedStop: null,
            infoWindowAnchor: null,
            markers: {}
        };
    }
    
    markerLoadHandler = (gMarker,index) => {
        
        var newState = {...this.state};
        
        newState.markers[index] = gMarker;
    
        this.setState(newState);
    };
    
    setClickedMarker = (marker, index) => {
        
        var newState = {...this.state};
        
        var markers = newState.markers;
        
        var selectedStop = marker ? marker.stop : null;
        var infoWindowAnchor = index !== null ? markers[index] : null;
        
        // to make sure 
        this.setState({
            newState,
            infoWindowAnchor:null
        });
        
        this.setState({
            newState,
            selectedStop,
            infoWindowAnchor: infoWindowAnchor
        });
    };

  render () {
    
    var markers = [];
    
    var stops = Array.from(this.props.stops);
    
    stops.forEach(function(stop)
    {
        markers.push({
            position: {lat: stop.lat, lng: stop.lng},
            icon: {
                url: '/img/map/marker.png',
                scaledSize: { height: 45, width: 30 }
            },
            stop: stop
        });
    });
      
    return (
        <>
        
        {markers.map((marker, index) => (
            <Marker
                
                position={marker.position}
                
                icon={marker.icon}
                
                key={index}
                
                onClick={(e) => this.setClickedMarker(marker, index)}
                
                onLoad={(gMarker) => this.markerLoadHandler(gMarker, index)}
                
                options={{
                    animation: null//window.google.maps.Animation.DROP
                }}
                
            />
            
        ))}
        
        {this.state.selectedStop && this.state.infoWindowAnchor && (
            <InfoWindow
              anchor={this.state.infoWindowAnchor}
              onCloseClick={() => this.setClickedMarker(null, null)}
            >
                
                <InfoWindowContent stop={this.state.selectedStop}/>
                
            </InfoWindow>
        )}
        
        </>
    )
  }
}

export default Makers;