import React from 'react';

import Carousel from 'react-bootstrap/Carousel'
import Image from 'react-bootstrap/Image'


class TripSlider extends React.Component {
    
    
    
    render () {
        
        return (
            <Carousel >
            
                {new Array(11).fill(null).map((elem, index) => (
                
                    <Carousel.Item
                    
                        key={index}
                        
                        interval={2000}
                        
                        className="text-center hearted-background"
                    >
                        <Image
                            fluid
                            src={"/img/carousel/"+(index + 1)+".jpg"}
                            alt={"slide-" + (index + 1)}
                        />
                        {/*
                        <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                        */}
                    </Carousel.Item>
                
                ))}
            
            </Carousel>
        );
    };

}

export default TripSlider;