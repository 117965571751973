import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';

import {Jumbotron,Container,Row,Col,Card} from 'react-bootstrap';

import Confetti from 'react-dom-confetti';


class TripCounter extends React.Component {
    
    constructor (props) {
        super(props);
        
        this.state = {
            duration : null,
            seconds  : null,
            show     : true,
            
            confetti_position: null
        }
    }
    
    theMoment = moment('03-31-2022 12:00', "MM-DD-YYYY HH:mm");
    
    current_positions  = ['cc','lt','rb','rt','lb'];
    confetti_positions = ['cc','lt','rb','rt','lb'];
    
    
    updateCountdown = () => {
        
        var seconds = this.theMoment.diff(moment(), 'seconds');
        
        if(seconds > 0)
        {
            this.setState({
                duration : moment.duration(seconds, 'seconds'),
                seconds  : seconds,
                show     : true,
                
                confetti_position: null
            })
            
            this.tickTimer();
        }
        else
        {
            if(seconds > -60 )
            {
                var pos = this.current_positions.shift();
                
                this.current_positions.push(pos);
                    
                this.setState({
                    duration : null,
                    seconds  : null,
                    show     : true,
                    
                    confetti_position: pos
                })
                
                 this.tickTimer();
            }
            else
            {
                this.setState({
                    duration : null,
                    seconds  : null,
                    show     : false,
                    
                    confetti_position: null
                })
            }
        }
        
    }
    
    tickTimer = () => {
        
        window.setTimeout(this.updateCountdown, 1000);
    }
    
    componentDidMount()
    {
        this.updateCountdown();
    }
    
    render () {
        
        const config = {
          angle: "161",
          spread: 360,
          startVelocity: "50",
          elementCount: "400",
          dragFriction: "0.15",
          duration: "8490",
          stagger: "2",
          width: "10px",
          height: "10px",
          perspective: "442px",
          colors: ["#db275a", "#a80000", "#0f0", "#00f", '#32a852']
        };
        
        var d = this.state.duration;
        
        var days    = d ? d.days()    : 0;
        var hours   = d ? d.hours()   : 0;
        var minutes = d ? d.minutes() : 0;
        var seconds = d ? d.seconds() : 0;
        
        return (
            <>
            
            {this.confetti_positions.map((pos) => {
                
                return (
                    <div className={"confetti-container confetti-container-" + pos}>
                        <Confetti
                            active={ pos == this.state.confetti_position }
                            config={ config }
                        />
                    </div>
                )
            })}
            
            <Jumbotron className="main-jumbotron text-center mb-0 hearted-background">
                <Container fluid={true}>
                    <h1 className="display-4">Santa Barbara</h1>
                    
                    {(this.state.show) && <Row className={"my-5"}>
                        
                        <Col className="col-timer" sm={{ span: 2, offset: 2 }} xs={3}>
                            <Card>
                                <h4 className="my-2">{days}</h4>
                                <p>Days</p>
                            </Card>
                        </Col>
                        
                        <Col className="col-timer" sm={2}  xs={3}>
                            <Card>
                                <h4 className="my-2">{hours}</h4>
                                <p>Hours</p>
                            </Card>
                        </Col>
                        
                        <Col className="col-timer" sm={2}  xs={3}>
                            <Card>
                                <h4 className="my-2">{minutes}</h4>
                                <p>Minutes</p>
                            </Card>
                        </Col>
                        <Col className="col-timer" sm={2}  xs={3}>
                            <Card>
                                <h4 className="my-2">{seconds}</h4>
                                <p>Seconds</p>
                            </Card>
                        </Col>
                    </Row>}
                    <p className="lead m-0">{this.theMoment.format('MMMM Do, YYYY HH:mma')}</p>
                </Container>
            </Jumbotron>
            </>
        );
    };

}

export default TripCounter;

