import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import {Jumbotron,Container,Row,Col} from 'react-bootstrap';

import stops from './map/stops.json';
import styles from './map/styles.json'; 
import Markers from './map/Markers';
import Road from './map/Road';

class TripMap extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            mapPanned: false
        };
    }

    truckJourneysRef = ({mapIsReady}) => {
        this.mapIsReady = mapIsReady;
    }
    
    handleLoad = (map) => {
        
        window.google.maps.event.addListenerOnce(map, 'idle', () =>
        {
            this.fitBounds(map);
        });
    }
    
    handleIdle = () => {
        
        if(!this.state.mapPanned)
        {
            this.setState({mapPanned: true });
            return;
        }
        
        // a little of animation
        window.setTimeout(this.mapIsReady, 500);
    }
    
    fitBounds = (map) => {
        
        const bounds = new window.google.maps.LatLngBounds();
        
        stops.map((stop) => {
            bounds.extend({lat: stop.lat, lng: stop.lng});
        });
        
        map.fitBounds(bounds);// auto-zoom
    };
    
    render () {
        
        return (
            <Jumbotron className="mb-0 p-0">
                <Container fluid={true}>
                    <Row className="">
                        <Col xs={12} className="px-0" style={{height: 550}} >
                            <LoadScript googleMapsApiKey="AIzaSyBsKDtsmisBixQxRQ5DcZQ--gBarpFhiZE">
                                <GoogleMap
                                    
                                    // required
                                    id='santa-barbara-trip'
                                    
                                    // required
                                    mapContainerStyle={{
                                        height: '550px',
                                        width: '100%'
                                    }}
                                    
                                    zoom={4}
                                    
                                    center={{ lat: 40.85, lng: -95.65 }}
                                    
                                    onLoad={this.handleLoad}
                                    
                                    onIdle={this.handleIdle}
                                    
                                    options={{
                                        mapTypeControl: false,
                                        streetViewControl: false,
                                        gestureHandling: 'cooperative',
                                        fullscreenControl: false,
                                        styles:styles
                                    }}
                                >
                                    <Markers stops={stops} />
                                    
                                    <Road stops={stops} />
                                    
                                </GoogleMap>
                            </LoadScript>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        );
    };

}

export default TripMap;