import React from 'react';
import { DirectionsService, DirectionsRenderer } from '@react-google-maps/api';

class Road extends React.Component {
    constructor (props) {
        super(props)
        
        this.state = {response: null}
    }
    
    directionsCallback =  (response) => {
        
        if (response !== null && !this.state.response) {
            if (response.status === 'OK') {
                this.setState({response})
            }
        }
    }
    
    render () {
        
        var stops = Array.from(this.props.stops);
        
        
        var origin = stops.shift();
        var destination = stops.pop();
        
        var waypoints = [];
        
        stops.forEach(function(stop)
        {
            waypoints.push({
                location: {lat: stop.lat, lng: stop.lng},
                stopover: true
            })
        })
        
        return (
            <>
                <DirectionsService
                    
                    // required
                    options={{
                        destination: destination,
                        origin: origin,
                        travelMode: 'DRIVING',
                        waypoints: waypoints
                    }}
                    
                    // required
                    callback={this.directionsCallback}
                />
                
                {
                    this.state.response !== null && (
                    <DirectionsRenderer
                        
                        // required
                        options={{
                            directions: this.state.response,
                            suppressMarkers : true,
                            polylineOptions: {
                                strokeColor: 'rgb(247, 7, 7)',
                                strokeWeight: 5,
                                clickable: false
                            },
                            preserveViewport: true,
                        }}
                    />)
                }
            </>
        );
    };
};

export default Road;