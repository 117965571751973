import React from 'react';

class InfoWindowContent extends React.Component {

  render () {
      
      var stop = this.props.stop
      
    return (
        <div className="infowindow-content">
        
            <p style={{fontWeight: 'bold', fontSize: '14px'}}>{stop.name}</p>
            <p><i className="text-muted">"{stop.quote}"</i></p>
            <p>{stop.answer}</p>
        </div>
    )
  }
}

export default InfoWindowContent;